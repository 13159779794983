<template>
  <div
    class="page"
    :style="
      (isBuyVip && !form.smart && isBuyVipNoPrompt == 1) ||
      (!isBuyVip && noBuyVipNoPrompt == 1)
        ? 'padding-bottom: 184rem'
        : ''
    "
  >
    <div class="goBack" @click="goBack">
      <i class="el-icon-back"></i>
      <span>填写投放信息</span>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      label-position="top"
      hide-required-asterisk
      ref="ruleForm"
    >
      <el-form-item
        label="投放的广告文件"
        :label-width="formLabelWidth"
        style="border-bottom: 2rem solid #f8f8f8"
      >
        <div class="flex-row">
          <div class="video-view">
            <!-- <img class="logo_seize" src="@/assets/img/logo_seize.png" alt="" /> -->
            <video
              :src="form.video_url"
              autoplay
              loop
              muted
              id="myVideo"
              class="video"
              @click="onClick_see()"
            ></video>
          </div>
          <div class="duration-desc">
            <span>视频时长：{{ realVideoDuration }}秒</span>
            <div>
              计费时长：{{ videoDuration }}秒<span style="color: #a4a6a5"
                >（四舍五入）</span
              >
            </div>
            <span v-if="videoWidth != 0"
              >视频分辨率：{{ videoWidth }} × {{ videoHeight }}（宽高比{{
                (videoWidth / videoHeight).toFixed(2)
              }}）</span
            >
            <div
              v-if="videoWidth != 0 && videoWidth < 960 && videoHeight < 540"
              class="tip flex-row align-center"
            >
              <img
                src="https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/mini/icon/yellow_warn.png"
                alt=""
              />
              当前视频分辨率较低投放效果较差，建议更换视频
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="投放广告的屏幕"
        :label-width="formLabelWidth"
        style="border-bottom: 2rem solid #f8f8f8; border-radius: 6rem 6rem 0 0"
      >
        <div class="screenList flex-row">
          <div
            v-for="(item, index) in screenList"
            :key="index"
            :class="{
              screenItem: true,
              active: currentScreenIndex == index,
              disabled: item.disabled,
            }"
            @click="clickScreen(item, index)"
          >
            <div v-if="item.disabled" class="disabled-view align-center">
              仅可投放分辨率宽高比在{{ item.widthHeightRatioBegin }}-{{
                item.widthHeightRatioEnd
              }}之间的视频
            </div>
            <div v-if="item.attachSub.length > 0" class="screen-type">
              {{
                item.attachSub.length == 1
                  ? "双屏幕"
                  : "多屏幕"
              }}
            </div>
            <img
              v-if="item.attachSub.length == 1"
              class="attachSub-img"
              src="@/assets/img/attachSub_img.png"
              alt=""
            />
            <img class="image" :src="item.image" alt="" />
            <span>{{ item.remark }}</span>
            <div
              class="device-info-btn flex-row align-center"
              @click.stop="changeDeviceInfo(item)"
            >
              <span
                :style="
                  currentDevice.name == item.name && showDeviceInfo
                    ? 'color:#32C3F6'
                    : ''
                "
                >设备信息</span
              >
              <img
                v-if="currentDevice.name == item.name && showDeviceInfo"
                class="down"
                src="@/assets/icon/up.png"
                alt=""
              />
              <!-- <img v-else class="down" src="@/assets/icon/down.png" alt="" /> -->
              <svg-icon
                v-else
                class="down-svg"
                iconClass="smallArrow"
              ></svg-icon>
            </div>
          </div>
        </div>
        <div class="device-info flex-row flex-wrap" v-if="showDeviceInfo">
          <div class="info-item flex-row align-center">
            <span class="label">设备名称：</span>
            <span class="value">{{ currentDevice.remark }}</span>
          </div>
          <div class="info-item flex-row align-center">
            <span class="label">屏幕数量：</span>
            <span class="value">1</span>
          </div>
          <div class="info-item flex-row align-center">
            <span class="label">屏幕分辨率：</span>
            <span class="value"
              >{{ currentDevice.resolutionWidth }}*{{
                currentDevice.resolutionHeight
              }}
              (即{{ aspectRatioFn() }})</span
            >
          </div>
          <div class="info-item flex-row align-center">
            <span class="label">开机时间：</span>
            <span class="value">{{ openTimeFn() }}</span>
          </div>
          <div
            v-if="currentDevice.peopleTraffic"
            class="info-item flex-row align-center"
          >
            <span class="label">人流量：</span>
            <span class="value">{{ currentDevice.peopleTraffic }}</span>
          </div>
          <div class="info-item flex-row align-center">
            <span class="label">广告展现方式：</span>
            <span class="value">图片/视频/声音</span>
          </div>
          <div
            v-if="currentDevice.carTraffic"
            class="info-item flex-row align-center"
          >
            <span class="label">车流量：</span>
            <span class="value">{{ currentDevice.carTraffic }}</span>
          </div>
          <div class="info-item flex-row align-center">
            <span class="label">投放方式：</span>
            <span class="value">在线自助投放</span>
          </div>
          <div class="info-item flex-row align-center">
            <span class="label">设备类型：</span>
            <span class="value">LED大屏</span>
          </div>
          <div
            v-if="currentDevice.size"
            class="info-item flex-row align-center"
          >
            <span class="label">屏幕尺寸：</span>
            <span class="value">{{ currentDevice.size }}</span>
          </div>
          <div class="info-item flex-row align-center">
            <span class="label">广告投放时间：</span>
            <span class="value">在线自助选择投放时间</span>
          </div>
          <div
            v-if="currentDevice.audience"
            class="info-item flex-row align-center max-width"
          >
            <span class="label">受众人群：</span>
            <span class="value">{{ currentDevice.audience }}</span>
          </div>
          <div
            v-if="currentDevice.address"
            class="info-item flex-row align-center max-width"
          >
            <span class="label">设备地址：</span>
            <span class="value">{{ currentDevice.address }}</span>
          </div>
          <div
            class="close flex-row justify-center align-center"
            @click="showDeviceInfo = false"
          >
            <img src="@/assets/icon/close.png" alt="" />
          </div>
        </div>
      </el-form-item>
      <el-form-item
        class="showTypeFormItem"
        v-if="currentScreenInfo"
        label="显示效果"
        :label-width="formLabelWidth"
        style="border-bottom: 2rem solid #f8f8f8"
      >
        <div :class="['main-view', currentScreenInfo.name]">
          <div class="title-view flex-row justify-between align-center">
            <span class="label" v-if="currentScreenInfo.attachSub.length > 0"
              >主屏幕（分辨率：{{ currentScreenInfo.resolutionWidth }}*{{
                currentScreenInfo.resolutionHeight
              }}）</span
            >
            <span class="label" v-else
              >分辨率：{{ currentScreenInfo.resolutionWidth }}*{{
                currentScreenInfo.resolutionHeight
              }}</span
            >
            <el-radio-group v-model="attachSubList[0]">
              <el-radio
                v-for="(item, index) in currentScreenInfo.showType"
                :key="index"
                :label="item"
                >{{
                  item == 0 ? "居中" : item == 1 ? "平铺" : "拉伸"
                }}</el-radio
              >
            </el-radio-group>
          </div>
          <div
            v-if="attachSubList[0] == 1"
            class="img-view flex-row justify-evenly"
          >
            <img
              class="img"
              v-for="index in tileNum1"
              :key="index"
              :src="videoThumbnailUrl"
            />
          </div>
          <div v-else class="img-view flex-row justify-evenly">
            <img
              :class="{
                img: true,
                type2: attachSubList[0] == 2 ? true : false,
              }"
              :src="videoThumbnailUrl"
            />
          </div>
        </div>
        <div
          v-if="currentScreenInfo.attachSub.length > 0"
          :class="['vice-view', currentScreenInfo.attachSub[0].name]"
        >
          <div class="title-view flex-row justify-between align-center">
            <div class="align-center">
              <span class="label"
                >辅屏幕（分辨率：{{
                  currentScreenInfo.attachSub[0].resolutionWidth
                }}*{{ currentScreenInfo.attachSub[0].resolutionHeight }}）</span
              >
              <el-popover
                class="popover"
                placement="top"
                trigger="hover"
                :visible-arrow="false"
                popper-class="quickPayPopover"
              >
                <div class="popover-content flex-col justify-between">
                  <span
                    >屏幕开机则与主屏幕同步投放，开机时间：{{
                      currentScreenInfo.attachSub[0].strOpenTime
                    }}，无需额外付费。</span
                  >
                </div>
                <div slot="reference" class="reference flex-row align-center">
                  <img src="@/assets/icon/warning2.png" alt="" />
                </div>
              </el-popover>
            </div>
            <el-radio-group v-model="attachSubList[1]">
              <el-radio
                v-for="(item, index) in currentScreenInfo.attachSub[0].showType"
                :key="index"
                :label="item"
                >{{
                  item == 0 ? "居中" : item == 1 ? "平铺" : "拉伸"
                }}</el-radio
              >
            </el-radio-group>
          </div>
          <div
            v-if="attachSubList[1] == 1"
            class="img-view flex-row justify-evenly"
          >
            <img
              class="img"
              v-for="index in tileNum2"
              :key="index"
              :src="videoThumbnailUrl"
            />
          </div>
          <div v-else class="img-view flex-row justify-evenly">
            <img
              :class="{
                img: true,
                type2: attachSubList[1] == 2 ? true : false,
              }"
              :src="videoThumbnailUrl"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item
        v-if="!form.smart"
        label="广告投放总次数"
        :label-width="formLabelWidth"
        prop="times"
        style="margin-bottom: 0rem; border-bottom: 2rem solid #f8f8f8"
      >
        <el-input-number
          v-model="form.times"
          :min="1"
          :max="maxTimes"
          label="每秒¥1.00"
          @blur="inputNumberBlur1"
        ></el-input-number>
        <span class="change-launch-type" @click="changeLaunchType"
          >试试智能投放</span
        >
        <span
          v-if="freeVipTimeTimes > 0 && freeVipTimeTimes <= 9999"
          class="date-desc"
        >
          * 如果您将可用的剩余广告时长全部用于此订单，可投放{{
            freeVipTimeTimes
          }}次。
        </span>
        <span v-if="freeVipTimeTimes > 9999" class="date-desc">
          * 如果您将可用的剩余广告时长全部用于此广告视频，可投放{{
            freeVipTimeTimes
          }}次，但单张订单最大可投放9999次。
        </span>
        <span
          v-if="form.times > 999"
          class="date-desc"
          style="margin-top: 6rem"
        >
          * 投放次数较大，创建订单可能会需要几分钟，稍后请耐心等待。
        </span>
      </el-form-item>
      <el-form-item
        v-if="!form.smart"
        label="预约开始投放时间点"
        :label-width="formLabelWidth"
        prop="first_time"
        style="
          margin-bottom: 0rem;
          border-radius: 0 0 6rem 6rem;
          border-bottom: 2rem solid #f8f8f8;
        "
      >
        <el-date-picker
          v-model="form.first_time"
          ref="datetimePicker"
          type="datetime"
          value-format="timestamp"
          :picker-options="pickerOptions"
          :clearable="false"
          placeholder="选择日期时间"
        >
        </el-date-picker>
        <img
          class="date-img"
          src="@/assets/icon/date.png"
          alt=""
          @click="$refs.datetimePicker.focus()"
        />
        <div v-if="superVip">
          <span class="date-desc" style="color: #f52f3e" v-if="videoDirect"
          >*
            该视频曾被审核通过，本次投放免审核，最快可预约5分钟后开始投放。</span
          >
          <span class="date-desc" v-else
          >*
            最早可投放时间为5分钟后（建议选择出行高峰或适合现场观看的时间）。</span
          >
        </div>
        <div v-else>
          <span class="date-desc" style="color: #f52f3e" v-if="videoDirect && currentScreenIsOff"
          >*
            该视频曾被审核通过，本次投放免审核，因大屏正在断网模式运行，最快只能创建明天投放的订单。</span
          >
          <span
            class="date-desc"
            style="color: #f52f3e"
            v-else-if="videoDirect && !currentScreenIsOff"
          >*
            该视频曾被审核通过，本次投放免审核，最快可预约5分钟后开始投放。</span
          >
          <span
            class="date-desc"
            style="color: #f52f3e"
            v-else-if="isFiveMinute && currentScreenIsOff"
          >*
            最早可投放时间为5分钟后（因大屏正在断网模式运行，最快只能创建明天投放的订单）。</span
          >
          <span class="date-desc" v-else-if="isFiveMinute && !currentScreenIsOff"
            >*
            最早可投放时间为5分钟后（建议选择出行高峰或适合现场观看的时间）。</span
          >
          <span class="date-desc" v-else
            >*
            最早可投放时间为明日大屏开机时间（建议选择出行高峰或适合现场观看的时间）。</span
          >
        </div>
        <span class="date-desc" style="margin-top: 6rem">
          * 系统会自动为您挑选离这个时间点最近的可投放时间，并请您确认。
        </span>
      </el-form-item>
      <el-form-item
        class="timesRadioGroup"
        label="投放时间间隔（如：每5分钟投放1次）"
        :label-width="formLabelWidth"
        v-if="form.times > 1 && !form.smart"
        style="border-radius: 6rem"
      >
        <el-radio-group
          v-model="form.interval_minute"
          size="medium"
          style="position: relative; bottom: 14rem"
        >
          <el-radio
            v-for="(item, index) in intervalMinuteList"
            :key="index"
            border
            :disabled="item < videoDuration / 60 && item != 0"
            :label="item"
          >
            <div v-if="item >= videoDuration / 60 || item == 0">
              <span v-if="item == 0">不间隔，连续投放</span>
              <span v-else-if="item < 60">每{{ item }}分钟</span>
              <span v-else-if="item < 1440">每{{ item / 60 }}小时</span>
              <span v-else>每{{ item / 1440 }}天</span>
            </div>
            <el-tooltip v-else placement="top">
              <!-- <div slot="content">视频时长已满{{ item }}分钟，无法选择此投放间隔</div> -->
              <div slot="content">所选投放间隔需大于视频时长</div>
              <div>
                <span v-if="item == 0">不间隔，连续投放</span>
                <span v-else-if="item < 60">每{{ item }}分钟</span>
                <span v-else-if="item < 1440">每{{ item / 60 }}小时</span>
                <span v-else>每{{ item / 1440 }}天</span>
              </div>
            </el-tooltip>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="!form.smart"
        label="抵扣优惠"
        :label-width="formLabelWidth"
        style="border-bottom: 2rem solid #f8f8f8"
      >
        <div v-if="superVip" class="no-discount">特权用户0元投放</div>
        <el-select
          v-else-if="discountOptions.length"
          v-model="discountValue"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in discountOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
        <div class="no-discount" v-else>您的账户中暂无可用的优惠</div>
      </el-form-item>
      <el-form-item
        v-if="form.smart"
        label="智能投放"
        :label-width="formLabelWidth"
        style="border-bottom: 2rem solid #f8f8f8"
      >
        <div class="smartLaunch-item flex-row align-center">
          <span class="smartLaunch-item_text" style="margin-right: 12rem"
            >从</span
          >
          <el-date-picker
            class="datePicker"
            v-model="form.smart_first_time"
            ref="datePicker"
            type="date"
            value-format="timestamp"
            :picker-options="smartPickerOptions"
            :clearable="false"
            placeholder="选择日期"
          >
          </el-date-picker>
          <img
            class="date-img"
            style="left: 40rem"
            src="@/assets/icon/date.png"
            alt=""
            @click="$refs.datePicker.focus()"
          />
          <span class="smartLaunch-item_text" style="margin-left: 12rem"
            >开始，</span
          >
          <span class="smartLaunch-item_text">连续</span>
          <el-input-number
            class="input_number"
            v-model="form.smartDays"
            :min="1"
            :max="100"
            :controls="false"
            @blur="inputNumberBlur4"
          ></el-input-number>
          <span class="smartLaunch-item_text">天</span>
        </div>
        <div
          class="smartLaunch-item flex-row align-center"
          style="margin-bottom: 20rem"
        >
          <span class="smartLaunch-item_text">每天投放</span>
          <el-input-number
            class="input_number"
            v-model="form.smartDaysTimes"
            :min="1"
            :max="smartDaysTimesMax"
            :controls="false"
            @blur="inputNumberBlur2"
            @change="showSmartIntervalMinuteDesc = false"
          ></el-input-number>
          <span class="smartLaunch-item_text">次，</span>
          <span class="smartLaunch-item_text">每</span>
          <el-input-number
            v-if="!smartNoInterval"
            class="input_number"
            v-model="form.smartIntervalMinute"
            :min="Math.ceil(videoDuration / 60)"
            :max="smartIntervalMinuteMax"
            :controls="false"
            @blur="inputNumberBlur3"
            @change="showSmartIntervalMinuteDesc = false"
          ></el-input-number>
          <el-input-number
            v-else
            class="input_number"
            v-model="smartNoIntervalMinute"
            :controls="false"
            disabled
          ></el-input-number>
          <span class="smartLaunch-item_text">分钟1次</span>
          <div class="smartNoInterval-view">
            <el-checkbox v-model="smartNoInterval"
              >不间隔，连续投放</el-checkbox
            >
            <span
              >（连续投放每天最多可投{{ smartNoIntervalMaxTimes() }}次）</span
            >
          </div>
        </div>
        <div
          v-if="showSmartIntervalMinuteDesc"
          class="warning-desc flex-row align-center"
        >
          <img
            src="https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/mini/icon/yellow_warn.png"
            alt=""
          />
          根据您的广告视频时长及所设投放次数计算，投放间隔最长只能设为{{
            form.smartIntervalMinute
          }}分钟
        </div>
        <span class="date-desc" style="margin-top: 0">
          *
          系统会优先选择每天的上下班/出行高峰时间，如果高峰时间已经被其它订单占用，则会从大屏早上开机时间开始，寻找可投放的时间。
        </span>
        <div class="title_box flex-row align-center">
          <i></i>
          <span
            >以上选择共投放{{
              form.smartDaysTimes * form.smartDays
            }}次，投放总时长：{{
              form.smartDaysTimes * form.smartDays * videoDuration
            }}秒</span
          >
        </div>
        <span v-if="superVip" class="date-desc" style="margin-top: 14rem">
          * 最终投放次数与时长以系统排期为准，提交订单后可查看实投次数。</span
        >
        <span v-else class="date-desc" style="margin-top: 14rem">
          *
          最终投放次数与时长以系统排期为准，提交订单后可查看实投次数与实扣时长。</span
        >
        <span
          class="date-desc"
          v-if="
            freeVipTime <
              form.smartDaysTimes * form.smartDays * videoDuration && !superVip
          "
          style="margin-top: 16rem"
        >
          * 需账户中当前屏幕剩余广告时长大于或等于这个值才能进行智能投放；
        </span>
        <span
          class="date-desc"
          v-if="
            freeVipTime <
              form.smartDaysTimes * form.smartDays * videoDuration && !superVip
          "
          style="margin-top: 16rem"
        >
          * 您账户中当前屏幕剩余广告时长：{{ freeVipTime }}秒，还缺{{
            form.smartDaysTimes * form.smartDays * videoDuration - freeVipTime
          }}秒；
        </span>
        <span
          class="date-desc"
          v-if="
            freeVipTime >=
              form.smartDaysTimes * form.smartDays * videoDuration && !superVip
          "
          style="margin-top: 16rem"
        >
          * 您账户中当前屏幕剩余广告时长：{{ freeVipTime }}秒；
        </span>
        <!-- <div
          class="date-desc"
          style="margin-top: 16rem"
          v-if="
            freeVipTime <
              form.smartDaysTimes * form.smartDays * videoDuration && !superVip
          "
        >
          * 您可以改小上面的参数，或<span
            style="color: #2bb3e3; text-decoration: underline; cursor: pointer"
            @click="$refs.refBuyFreeVipTimeDialog.showDialog()"
            >去购买时长</span
          >；
        </div> -->
        <span class="date-desc" style="margin-top: 16rem">
          * 如果投放次数很大，创建订单可能会需要几分钟。
        </span>
        <span class="change-launch-type" @click="changeLaunchType"
          >返回常规投放</span
        >
      </el-form-item>
    </el-form>
    <!-- 常规投放底部 start -->
    <div v-if="!form.smart" class="footer justify-between align-center">
      <span class="total-duration"
        >投放广告总时长：{{ videoDuration * form.times }}秒</span
      >
      <div class="flex-row">
        <div class="flex-col align-start justify-center">
          <div class="paid-in">
            应付金额：<span>￥{{ payAmount }}</span>
          </div>
          <div
            v-if="discountAmount != 0 && discountValue == 2"
            class="discount"
          >
            优惠：<span>-￥{{ discountAmount }}</span>
          </div>
          <div
            v-if="discountAmount != 0 && discountValue == 3"
            class="discount"
          >
            抵扣：<span
              >-￥{{ discountAmount }}（{{ discountAmount }}秒*1元/秒）</span
            >
          </div>
        </div>
        <div
          v-if="!submitStatus"
          class="submit-order"
          id="primary-btn"
          @click="submitOrder"
        >
          提交订单
        </div>
        <div
          v-else
          class="loading-btn flex-row justify-center align-center"
          id="primary-btn"
        >
          <img src="../../assets/img/loading.gif" width="60" height="60" />
        </div>
      </div>
    </div>
    <!-- 常规投放底部 end -->
    <!-- 智能投放底部 start -->
    <div v-if="form.smart" class="footer justify-between align-center">
      <span class="total-duration"
        >投放广告总时长：{{
          form.smartDaysTimes * form.smartDays * videoDuration
        }}秒</span
      >
      <div
        class="flex-row"
        v-if="
          freeVipTime >= form.smartDaysTimes * form.smartDays * videoDuration ||
          superVip
        "
      >
        <div class="flex-col align-start justify-center">
          <div class="paid-in">应付金额：<span>￥0</span></div>
          <div v-if="!superVip" class="discount">
            抵扣：<span
              >-￥{{
                form.smartDaysTimes *
                form.smartDays *
                videoDuration *
                videoUnitPrice
              }}（{{
                form.smartDaysTimes * form.smartDays * videoDuration
              }}秒*1元/秒）</span
            >
          </div>
        </div>
        <div
          v-if="!submitStatus"
          class="submit-order"
          id="primary-btn"
          @click="submitOrder"
        >
          提交订单
        </div>
        <div
          v-else
          class="loading-btn flex-row justify-center align-center"
          id="primary-btn"
        >
          <img src="../../assets/img/loading.gif" width="60" height="60" />
        </div>
      </div>
      <div v-else class="flex-row">
        <div class="flex-row align-center">
          <span
            >缺{{
              form.smartDaysTimes * form.smartDays * videoDuration -
              freeVipTime
            }}秒</span
          >
          <!-- <span
            class="paid-in"
            style="cursor: pointer"
            @click="$refs.refBuyFreeVipTimeDialog.showDialog()"
            >去购买时长</span
          > -->
        </div>
        <div class="submit-order" id="disable-btn">无法提交</div>
      </div>
    </div>
    <!-- 智能投放底部 end -->
    <pre-video-dialog ref="refPreVideo" :videoData="preVideoData" />
    <launchTimeDialog ref="refLaunchTime" :orderData="launchTimeDialogData" />
    <createOrderErrDialog ref="refCreateOrderErr" />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-destructuring */

import {
  createOrder,
  getConfig,
  getUserInfo,
  getMp4VideoUrl,
} from "@/api/request";
import bus from "@/utils/bus";
import preVideoDialog from "@/components/preVideoDialog.vue";
import createOrderErrDialog from "@/components/createOrderErrDialog.vue";
import moment from "moment";
import launchTimeDialog from "./components/launchTimeDialog.vue";

export default {
  components: {
    preVideoDialog,
    createOrderErrDialog,
    launchTimeDialog,
  },
  data() {
    return {
      videoWidth: 0,
      videoHeight: 0,
      preVideoData: {}, // 预览视频数据
      launchTimeDialogData: {}, // 确定订单投放时间弹窗的数据
      discountValue: "1",
      videoDuration: 0, // 视频时长（四舍五入）
      realVideoDuration: 0, // 真实视频时长
      videoUnitPrice: 1, // 视频每秒价格
      maxTimes: 99, // 最大可投放次数（默认普通用户99次）
      formLabelWidth: "300rem",
      submitStatus: false,
      superVip: false,
      superVipName: [], // 特权用户等投放的屏幕
      freeVipTime: 0,
      isBuyVip: false,
      intervalMinuteList: [],
      currentScreenIndex: 0, // 当前屏幕
      strOpenTimeList: [], // 默认营业时间段
      defaultFirstTime: "", // 计算得出的默认最快可投放时间点
      isFiveMinute: false, // 是否是特殊用户（特殊用户最快5分钟后投放）
      freeVipTimeTimes: 0, // 全部可用时长可以投放的总次数
      currentDevice: {
        name: "", // 屏幕英文name
      },
      showDeviceInfo: false,
      discountOptions: [
        {
          name: "option1",
          value: "1",
          label: "不使用剩余时长",
          disabled: false,
        },
        {
          name: "option2",
          value: "2",
          label: "新人特惠，1元投放60秒",
          disabled: false,
        },
        {
          name: "option3",
          value: "3",
          label: "屏幕剩余广告时长，可用0s",
          disabled: false,
        },
      ],
      pickerOptions: {
        // 默认为普通用户，禁用明天之前的日期（今天不可选）
        disabledDate(time) {
          // Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() - 60 * 60 * 1000 * 24 < Date.now() - 8.64e7;
        },
        selectableRange: [], // 可选时间段
      },
      smartPickerOptions: {
        // 默认禁用明天之前的日期（今天不可选）至明年的今天的日期
        disabledDate(time) {
          const currentDate = new Date();
          const nextYearToday = new Date(
            currentDate.getFullYear() + 1,
            currentDate.getMonth(),
            currentDate.getDate()
          );
          const nextDateTime = time.getTime() - 60 * 60 * 1000 * 24;
          // Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return (
            nextDateTime < Date.now() - 8.64e7 ||
            time.getTime() > nextYearToday.getTime()
          );
        },
      },
      form: {
        video_url: "",
        times: 10,
        // first_time: new Date(new Date().getTime() + 60 * 60 * 1000 * 24), // 默认在现在时间基础上增加24小时
        first_time: "", // 常规投放默认投放时间
        smart_first_time: "", // 智能投放默认投放时间
        interval_minute: 10,
        use_new_discount: false,
        use_free_vip_time: false,
        from_platform: "web",
        attach_order_id: "",
        name: "", // 屏幕名称
        smart: false, // 是不是智能投放
        smartDays: 10, // 连续几天，1-100天
        smartDaysTimes: 1, // 每天投几次，1-999次
        smartIntervalMinute: 1, // 智能间隔(分钟/次)
      },
      rules: {
        times: [
          { required: true, message: "请输入广告投放总次数", trigger: "blur" },
        ],
        first_time: [
          {
            required: true,
            message: "请选择预约开始投放时间点",
            trigger: "blur",
          },
        ],
      },
      smartIntervalMinuteMax: 100, // 智能投放最大间隔
      smartDaysTimesMax: 999, // 每天最大可投放几次
      smartNoInterval: false, // 智能投放是否是不间隔投放
      smartNoIntervalMinute: 0, // 智能投放不间隔投放时间间隔
      showSmartIntervalMinuteDesc: false,
      noPrompt: true,
      videoDirect: false, // 视频是否能够免审核
      videoThumbnailUrl: "",
      attachSubList: [],
      tileNum1: 1, // 主屏图片平铺的数量
      tileNum2: 1, // 辅屏图片平铺的数量
      todayOpenTimeDifference: 0, // 大屏开机时间差（时间戳的差值）
      currentScreenIsOff: false, // 当前所选屏幕是否断网
    };
  },
  computed: {
    // 普通用户升级为黄金会员的不再提示
    noBuyVipNoPrompt() {
      return localStorage.getItem("noBuyVipNoPrompt") || 0;
    },
    // 黄金会员一折充值的不再提示
    isBuyVipNoPrompt() {
      return localStorage.getItem("isBuyVipNoPrompt") || 0;
    },
    // 获取用户的信息
    userInfoObj() {
      return this.$store.state.userInfo;
    },
    // 屏幕列表
    screenList() {
      if (this.$store.state.screenList.length) {
        // if (this.superVip) {
        //   // 特权用户能投放的屏幕
        //   if (this.superVipName.length) {
        //     const currentScreenList = [];
        //     this.superVipName.forEach((itemName) => {
        //       const findItem = this.$store.state.screenList.find(
        //         (v) => v.name == itemName
        //       );
        //       currentScreenList.push(findItem);
        //     });
        //     return currentScreenList;
        //   }
        //   return [];
        // }
        // if (!this.superVip) {
        // 过滤已下架的屏幕
        const currentScreenList = this.$store.state.screenList.filter(
          (v) => !v.down
        );
        return currentScreenList;
        // }
      }
      return [];
    },
    currentScreenInfo() {
      if (this.screenList.length) {
        let findV = "";
        const currentScreenName =
          this.screenList[this.currentScreenIndex].name;
        findV = this.screenList.find(
          (v) => v.name == currentScreenName
        );
        // 如果主屏信息找不到
        if (!findV) {
          let findV2 = "";
          this.screenList.forEach((item) => {
            if (item.attachSub.length > 0) {
              if (item.attachSub.find((v) => v.name == currentScreenName)) {
                findV2 = item.attachSub.find(
                  (v) => v.name == currentScreenName
                );
              }
            }
          });
          if (findV2) {
            findV = findV2;
          }
        }
        // console.log(findV);
        return findV;
      }
      return "";
    },
    // 实付金额
    payAmount() {
      /**
       * fullPrice  全价
       * payAmountNum  实付金额
       * videoUnitPrice  单价（1元）
       * form.times  次数
       */
      if (this.superVip) {
        return 0;
      }
      if (this.discountValue == "2") {
        // const payAmountNum =
        //   this.videoDuration * this.videoUnitPrice * (this.form.times - 1) + 1;
        // 【新人优惠】如果总时长大于60s，实付 =  （ 总时长 - 60s ）* 单价 + 1元；否则，实付1元。
        let payAmountNum = 1;
        if (this.videoDuration * this.form.times > 60) {
          payAmountNum =
            (this.videoDuration * this.form.times - 60) * this.videoUnitPrice +
            1;
        }
        return payAmountNum;
      }
      if (this.discountValue == "3") {
        // 【时长抵扣】如果当剩余时长小于投放总时长时，实付 =  （ 总时长 - 可扣除免费时长 ）* 单价
        let payAmountNum = 0;
        if (this.freeVipTime < this.videoDuration * this.form.times) {
          payAmountNum =
            (this.videoDuration * this.form.times - this.freeVipTime) *
            this.videoUnitPrice;
        }
        return payAmountNum;
      }
      const fullPrice =
        this.videoDuration * this.form.times * this.videoUnitPrice;
      return fullPrice;
    },
    // 优惠金额
    discountAmount() {
      /**
       * fullPrice  全价
       * payAmountNum  实付金额
       * videoUnitPrice  单价（1元）
       * form.times  次数
       */
      const fullPrice =
        this.videoDuration * this.form.times * this.videoUnitPrice;
      if (this.superVip) {
        return fullPrice;
      }
      if (this.discountValue == "2") {
        // const payAmountNum =
        //   this.videoDuration * this.videoUnitPrice * (this.form.times - 1) + 1;
        // 【新人优惠】如果总时长大于60s，实付 =  （ 总时长 - 60s ）* 单价 + 1元；否则，实付1元。
        let payAmountNum = 1;
        if (this.videoDuration * this.form.times > 60) {
          payAmountNum =
            (this.videoDuration * this.form.times - 60) * this.videoUnitPrice +
            1;
        }
        return fullPrice - payAmountNum;
      }
      if (this.discountValue == "3") {
        // 【时长抵扣】如果当剩余时长小于投放总时长时，实付 =  （ 总时长 - 可扣除免费时长 ）* 单价
        let payAmountNum = 0;
        if (this.freeVipTime < this.videoDuration * this.form.times) {
          payAmountNum =
            (this.videoDuration * this.form.times - this.freeVipTime) *
            this.videoUnitPrice;
        }
        return fullPrice - payAmountNum;
      }
      return 0;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "form.first_time": function (val) {
      // 默认可选时间段
      const newVal = new Date(val);
      // console.log(newVal, this.defaultFirstTime);
      // 如果选择的时间为当天日期(判断年月日是否相等)
      const isCurrentDate = newVal.getFullYear() === this.defaultFirstTime.getFullYear()
          && newVal.getMonth() === this.defaultFirstTime.getMonth()
          && newVal.getDate() === this.defaultFirstTime.getDate();
      // console.log('选择的时间为当天日期', isCurrentDate);
      if (isCurrentDate) {
        // 如果选择的时间小于当天默认时间
        if (
          newVal.getHours() < this.defaultFirstTime.getHours() ||
          (newVal.getHours() == this.defaultFirstTime.getHours() &&
            newVal.getMinutes() < this.defaultFirstTime.getMinutes())
        ) {
          this.form.first_time = this.defaultFirstTime.getTime();
        }
        const currentDateTime = `${moment(
          Number(this.defaultFirstTime.getTime())
        ).format("HH:mm")}`;
        const endOpenTime = this.strOpenTimeList[0].split("-")[1];
        const newStrOpenTimeList = [];
        newStrOpenTimeList.push(`${currentDateTime}:00 - ${endOpenTime}:00`);
        this.pickerOptions.selectableRange = newStrOpenTimeList;
      } else {
        const openBegin = this.strOpenTimeList[0].split("-")[0];
        const openEnd = this.strOpenTimeList[0].split("-")[1];
        this.pickerOptions.selectableRange = `${openBegin}:00 - ${openEnd}:00`;
      }
    },
    smartNoInterval() {
      // 取消勾选不间隔时，间隔自动设为最小可投放间隔
      // this.form.smartIntervalMinute = Math.ceil(this.videoDuration / 60);
      this.countSmartDaysTimes();
    },
    "form.smartDaysTimes": function () {
      this.countSmartDaysTimes();
    },
    "form.smartIntervalMinute": function () {
      this.countSmartDaysTimes();
    },
    attachSubList: {
      handler(newVal) {
        if (this.videoWidth && this.videoHeight) {
          this.tileNumInit(newVal);
        }
      },
      deep: true, // 开启深度监听
    },
  },
  created() {
    this.superVip = this.userInfoObj.businessInfo.superVip;
    this.isFiveMinute = this.userInfoObj.isFiveMinute;
    this.superVipName = this.userInfoObj.businessInfo.superVipName;
    this.isBuyVip = this.userInfoObj.businessInfo.isBuyVip;
    // 如果有已选屏幕
    if (this.$route.query.name) {
      this.currentScreenIndex = this.screenList.findIndex(
        (v) => v.name == this.$route.query.name
      );
      // 如果找不到已选屏幕（假如已选屏幕已下架），则默认选中第一块屏幕
      if (this.currentScreenIndex == -1) {
        this.currentScreenIndex = 0;
      }
    }
    const initScreenName = this.screenList[this.currentScreenIndex].name;
    if (this.userInfoObj.businessInfo.totalTime[initScreenName]) {
      this.freeVipTime = this.userInfoObj.businessInfo.totalTime[initScreenName];
    } else {
      this.freeVipTime = 0;
    }
    this.form.name = this.screenList[this.currentScreenIndex].name; // 定义提交订单时的屏幕
    // 获取url中的模板或者视频信息
    this.form.video_url = this.$route.query.videoUrl; // 视频路径
    // this.realVideoDuration = this.$route.query.realVideoDuration.toString(); // 视频真实时长
    this.realVideoDuration = Number(
      this.$route.query.realVideoDuration
    ).toFixed(2); // 视频真实时长
    this.videoDuration = Number(this.realVideoDuration).toFixed(0); // 视频四舍五入后的时长
    // 判断视频是否能够免审核
    this.getMp4VideoDirect()
      .then(() => {
        // 获取配置信息
        this.getConfigEvent(false, true);
      })
      .catch(() => {
        // 获取配置信息
        this.getConfigEvent(false, true);
      });
    // 初始化抵扣优惠信息
    this.initDiscount();
    // 如果是再次投放携带数据编辑的
    if (this.$route.query.times) {
      this.form.attach_order_id = this.$route.query.attachOrderId;
      this.form.times = Number(this.$route.query.times);
      this.form.interval_minute = Number(this.$route.query.intervalMinute);
      const showTypeArr = Object.values(
        JSON.parse(this.$route.query.showTypeMap)
      );
      this.attachSubList = showTypeArr;
      if (this.attachSubList.length == 0) {
        this.attachSubListInit();
      }
    } else {
      this.attachSubListInit();
    }
    // 如果是再次投放的智能投放携带数据编辑的
    if (this.$route.query.smart) {
      this.form.smart = true;
      this.form.smartDays = Number(this.$route.query.smartDays);
      this.form.smartDaysTimes = Number(this.$route.query.smartDaysTimes);
      // 判断是不是不间隔投放
      if (this.$route.query.smartIntervalMinute == 0) {
        this.smartNoInterval = true;
      } else {
        this.smartNoInterval = false;
        this.form.smartIntervalMinute = Number(
          this.$route.query.smartIntervalMinute
        );
      }
    } else {
      // 初始化智能投放的投放间隔
      this.form.smartIntervalMinute = Math.ceil(this.videoDuration / 60);
    }
    // 计算视频的宽高比
    this.$nextTick(() => {
      const videoElement = document.getElementById("myVideo");
      videoElement.addEventListener("loadedmetadata", () => {
        this.videoWidth = videoElement.videoWidth;
        this.videoHeight = videoElement.videoHeight;
        this.tileNumInit(this.attachSubList);
        this.screenListInit();
      });
    });
  },
  mounted() {
    // 动态改变客服悬浮按钮的位置
    bus.$emit("isAddLaunchInfo", false);
  },
  methods: {
    // 初始化抵扣优惠信息
    initDiscount() {
      // 重置优惠列表
      this.discountOptions = [
        {
          name: "option1",
          value: "1",
          label: "不使用剩余时长",
          disabled: false,
        },
        {
          name: "option2",
          value: "2",
          label: "新人特惠，1元投放60秒",
          disabled: false,
        },
        {
          name: "option3",
          value: "3",
          label: "屏幕剩余广告时长，可用0s",
          disabled: false,
        },
      ];
      // 如果是特权用户，就不需要计算别的优惠信息了
      if (this.superVip) {
        return false;
      }
      // 如果没有新人折扣，并且账户可用时长为0
      if (
        this.userInfoObj.businessInfo.newDiscountStatus != "0" &&
        this.freeVipTime == 0
      ) {
        this.discountOptions = [];
        return false;
      }
      // 如果没有新人优惠，则删除该项优惠
      if (this.userInfoObj.businessInfo.newDiscountStatus != "0") {
        const index = this.discountOptions.findIndex(
          (v) => v.name == "option2"
        );
        if (index != -1) {
          this.discountOptions.splice(index, 1);
        }
      } else {
        const index = this.discountOptions.findIndex(
          (v) => v.name == "option2"
        );
        if (index != -1) {
          // 如果有新人优惠，则默认勾选该优惠
          this.discountValue = "2";
        }
      }
      // 如果免费时长为0，则禁选黄金会员选项，并且切换勾选的优惠
      if (this.freeVipTime == 0) {
        const index = this.discountOptions.findIndex(
          (v) => v.name == "option3"
        );
        if (index != -1) {
          this.discountOptions[
            index
          ].label = `屏幕剩余广告时长，可用${this.freeVipTime}s`;
          this.discountOptions[index].disabled = true;
          // 如果有新人优惠，则默认切换勾选该优惠
          if (this.userInfoObj.businessInfo.newDiscountStatus == "0") {
            this.discountValue = "2";
          } else {
            this.discountValue = "1";
          }
        }
      }
      // 如果免费时长大于0
      if (this.freeVipTime > 0) {
        const index = this.discountOptions.findIndex(
          (v) => v.name == "option3"
        );
        if (index != -1) {
          this.discountOptions[
            index
          ].label = `屏幕剩余广告时长，可用${this.freeVipTime}s`;
          this.discountOptions[index].disabled = false;
          this.discountValue = "3";
        }
      }
      // 重置使用的优惠标识
      this.form.use_new_discount = false;
      this.form.use_free_vip_time = false;
    },
    // 判断视频是否能够免审核
    getMp4VideoDirect() {
      return new Promise((resolve, reject) => {
        getMp4VideoUrl({
          video_url: this.form.video_url,
        })
          .then((res) => {
            this.videoDirect = res.direct;
            this.videoThumbnailUrl = res.videoThumbnailUrl;
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    // 获取配置信息
    getConfigEvent(completePayment = false, isFirst = false) {
      getConfig({
        name: this.screenList[this.currentScreenIndex].name,
      }).then((res) => {
        const newArr = res.intervalMinute.map((item) => parseInt(item));
        this.intervalMinuteList = newArr;
        this.strOpenTimeList = res.strOpenTimeList;
        this.currentScreenIsOff = res.isOff;
        this.todayOpenTimeDifference = res.todayOpenEnd - res.todayOpenBegin;
        // 初始化智能投放的每天投放次数和投放间隔
        if (isFirst) {
          const allMinute = this.todayOpenTimeDifference / 60;
          this.form.smartDaysTimes = Math.floor(
            allMinute / this.form.smartIntervalMinute
          );
          if (this.form.smartDaysTimes > 999) {
            this.form.smartDaysTimes = 999;
          }
        }
        this.countSmartDaysTimes();
        // 处理最大可投放次数
        if (this.superVip) {
          // 特权用户
          this.maxTimes = Number(res.superVipMaxBuyTimes);
        } else {
          // 普通用户&&黄金会员
          this.maxTimes = this.countMaxTimes();
        }
        // 计算全部可用时长可以投放的总次数
        if (this.freeVipTime > 0) {
          this.freeVipTimeTimes = Math.floor(
            this.freeVipTime / this.videoDuration
          ); // 向下取整
        }
        // 如果页面投放次数大于最大可投放次数
        if (this.form.times > this.maxTimes) {
          this.form.times = this.maxTimes;
        }
        // 如果是弹窗里面的“已完成支付”等触发
        if (completePayment) return;
        // console.log("最大可投放次数：", this.maxTimes);
        // 所有当天（即23:59:59前）创建的订单，最快可投放时间，都是第二天早上大屏开机时间
        // 如果是特权用户、特殊用户或者视频能够免审核并且所选屏幕没有断网，则最快5分钟可投放
        if (
          this.superVip ||
          ((this.isFiveMinute || this.videoDirect) && !this.currentScreenIsOff)
        ) {
          const realFirstTime =
            (new Date().getTime() + 60 * res.superVipAfterMinuteBuy * 1000) /
            1000;
          // 如果时间在0点到最晚营业时间之间
          if (realFirstTime < res.todayOpenEnd) {
            // 特权用户或者特殊用户默认禁用今天之前的日期（今天可选）
            this.pickerOptions = {
              disabledDate(time) {
                return time.getTime() < Date.now() - 8.64e7;
              },
              selectableRange: []
            };
          }
        } else {
          // 恢复默认，禁用明天之前的日期（今天不可选）
          this.pickerOptions = {
            disabledDate(time) {
              return time.getTime() - 60 * 60 * 1000 * 24 < Date.now() - 8.64e7;
            },
            selectableRange: []
          };
        }
        this.initFirstTime(res);
        this.initSmartFirstTime();
      });
    },
    // 计算智能投放最快可投放日期
    initSmartFirstTime() {
      const smartFirstTime = new Date();
      smartFirstTime.setDate(smartFirstTime.getDate() + 1);
      smartFirstTime.setHours(0);
      smartFirstTime.setMinutes(0);
      smartFirstTime.setSeconds(0);
      smartFirstTime.setMilliseconds(0);
      this.form.smart_first_time = smartFirstTime.getTime();
    },
    // 重新定义默认的投放时间
    initFirstTime(res) {
      const firstTime = new Date();
      let realFirstTime = "";
      const openTimeHours = Number(res.strOpenTime.split("-")[0].split(":")[0]);
      const openTimeMinutes = Number(
        res.strOpenTime.split("-")[0].split(":")[1]
      );
      // console.log(openTimeHours, openTimeMinutes);
      firstTime.setDate(firstTime.getDate() + 1);
      firstTime.setHours(openTimeHours);
      firstTime.setMinutes(openTimeMinutes);
      firstTime.setSeconds(0);
      firstTime.setMilliseconds(0);
      // console.log('时间控件默认显示时间：', moment(firstTime).format("YYYY-MM-DD  HH:mm:ss"));
      if (
        this.superVip ||
        ((this.isFiveMinute || this.videoDirect) && !this.currentScreenIsOff)
      ) {
        realFirstTime = new Date(
          new Date().getTime() + 60 * res.superVipAfterMinuteBuy * 1000
        );
        const realFirstTimeUnix =
          (new Date().getTime() + 60 * res.superVipAfterMinuteBuy * 1000) /
          1000;
        // console.log(realFirstTimeUnix, res.todayOpenBegin);
        // 如果最快5分钟后，还未到营业时间，则设置为营业时间
        if (realFirstTimeUnix < res.todayOpenBegin) {
          realFirstTime.setHours(openTimeHours);
          realFirstTime.setMinutes(openTimeMinutes);
          realFirstTime.setSeconds(0);
          realFirstTime.setMilliseconds(0);
        }
        // 如果最快5分钟后，超过了当天营业时间，则设置为第二天营业时间
        if (realFirstTimeUnix > res.todayOpenEnd) {
          realFirstTime = firstTime;
        }
        console.log(
          "5分钟用户，最快可选择的时间：",
          moment(realFirstTime).format("YYYY-MM-DD  HH:mm:ss")
        );
      } else {
        realFirstTime = firstTime;
        console.log(
          "普通用户，最快可选择的时间：",
          moment(realFirstTime).format("YYYY-MM-DD  HH:mm:ss")
        );
      }
      this.form.first_time = firstTime.getTime();
      this.defaultFirstTime = realFirstTime;
    },
    // 普通用户&&黄金会员计算最大可投放次数
    countMaxTimes() {
      const num = Math.floor(this.freeVipTime / this.videoDuration); // 向下取整
      if (this.isBuyVip && num < 999) {
        return 999;
      }
      if (!this.isBuyVip && num < 99) {
        return 99;
      }
      if (num > 9999) {
        return 9999;
      }
      return num;
    },
    // 查询用户信息
    getUserInfo() {
      getUserInfo({}).then((res) => {
        this.$store.dispatch("setUserInfoActions", res.userInfo);
        this.superVip = res.userInfo.businessInfo.superVip;
        this.isFiveMinute = res.userInfo.isFiveMinute;
        this.superVipName = res.userInfo.businessInfo.superVipName;
        this.isBuyVip = res.userInfo.businessInfo.isBuyVip;
        const initScreenName = this.screenList[this.currentScreenIndex].name;
        if (res.userInfo.businessInfo.totalTime[initScreenName]) {
          this.freeVipTime = res.userInfo.businessInfo.totalTime[initScreenName];
        } else {
          this.freeVipTime = 0;
        }
        this.initDiscount();
        this.getConfigEvent(true); // 重新获取选中屏幕的配置信息
      });
    },
    // 切换投放的方式
    changeLaunchType() {
      this.form.smart = !this.form.smart;
      // 如果常规投放的时间间隔没有取到（比如智能投放是3分钟，常规投放则取不到值），则取初始值10分钟
      if (!this.intervalMinuteList.includes(this.form.interval_minute)) {
        this.form.interval_minute = 10;
      }
    },
    // 点击屏幕
    clickScreen(item, index) {
      if (item.disabled) return;
      if (this.currentScreenIndex == index) return;
      this.showDeviceInfo = false;
      this.currentScreenIndex = index;
      const initScreenName = item.name;
      if (this.userInfoObj.businessInfo.totalTime[initScreenName]) {
        this.freeVipTime = this.userInfoObj.businessInfo.totalTime[initScreenName];
      } else {
        this.freeVipTime = 0;
      }
      this.attachSubListInit();
      this.initDiscount();
      this.getConfigEvent(); // 重新获取选中屏幕的配置信息
    },
    goBack() {
      // 当用户没有上一条路由的历史记录，出现点击返回按钮没有反应时，下面的代码用来判断有没有上一条路由的历史记录,如果没有则返回首页
      if (window.history.length <= 1) {
        this.$router.replace({ path: "/home" });
        return false;
      }
      this.$router.go(-1);
    },
    // 点击完成支付
    completePayment() {
      this.getUserInfo();
    },
    submitOrder() {
      // console.log(this.form);
      if (this.screenList[this.currentScreenIndex].name == 'demo') {
        this.$message({
          type: "warning",
          center: true,
          message: "当前屏幕为演示屏幕，仅供体验",
        });
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // this.submitStatus = true;
          bus.$emit("openLoadingDialog", {
            type: "loading",
            loadingTxt: "正在排期，系统会自动过滤冲突时间...",
          });
          // 重新定义提交订单时的屏幕
          this.form.name = this.screenList[this.currentScreenIndex].name;
          let params = "";
          if (!this.form.smart) {
            // 当投放次数为1次时，默认时间间隔为10分钟
            if (this.form.times == 1) {
              this.form.interval_minute = 10;
            }
            // 如果选择了新人优惠
            if (this.discountValue == "2") {
              this.form.use_new_discount = true;
            }
            // 如果选择了黄金会员优惠
            if (this.discountValue == "3") {
              this.form.use_free_vip_time = true;
            }
            // params = { ...this.form };
            // params.first_time = parseInt(params.first_time / 1000);
            params = {
              video_url: this.form.video_url,
              times: this.form.times,
              first_time: parseInt(this.form.first_time / 1000),
              interval_minute: this.form.interval_minute,
              use_new_discount: this.form.use_new_discount,
              use_free_vip_time: this.form.use_free_vip_time,
              from_platform: this.form.from_platform,
              attach_order_id: this.form.attach_order_id,
              name: this.form.name,
              smart: this.form.smart,
            };
          } else {
            // 智能投放
            params = {
              video_url: this.form.video_url,
              first_time: parseInt(this.form.smart_first_time / 1000),
              use_free_vip_time: true,
              from_platform: this.form.from_platform,
              attach_order_id: this.form.attach_order_id,
              name: this.form.name,
              smart: this.form.smart,
              smart_days_times: this.form.smartDaysTimes,
              smart_interval_minute: this.smartNoInterval
                ? this.smartNoIntervalMinute
                : this.form.smartIntervalMinute,
              smart_days: this.form.smartDays,
            };
          }
          const showTypeObj = {};
          // console.log(this.attachSubList, this.currentScreenInfo);
          this.attachSubList.forEach((item, index) => {
            if (index == 0) {
              showTypeObj[this.currentScreenInfo.name] = item;
            }
            if (index > 0) {
              showTypeObj[this.currentScreenInfo.attachSub[index - 1].name] =
                item;
            }
          });
          params.show_type_map = showTypeObj;
          createOrder(params)
            .then((res) => {
              // console.log(res);
              // this.submitStatus = false;
              bus.$emit("closeLoadingDialog");
              this.launchTimeDialogData = {
                orderId: res.orderInfo.orderId,
                remark: this.screenList[this.currentScreenIndex].remark,
                name: this.screenList[this.currentScreenIndex].name,
                times: res.orderInfo.times,
                intervalMinute: res.orderInfo.intervalMinute,
                beginUnix: res.orderInfo.beginUnix,
                smart: res.orderInfo.smart,
                smartDaysTimes: res.orderInfo.smartDaysTimes,
                videoDirect: this.videoDirect,
              };
              // 异步延迟打开弹窗
              setTimeout(() => {
                this.openLaunchTimeDialog();
              }, 100);
            })
            .catch((code) => {
              // this.submitStatus = false;
              bus.$emit("closeLoadingDialog");
              if (code == "21003") {
                // 创建订单，待支付订单超出限制
                this.$refs.refCreateOrderErr.openDialog();
              }
              if (code == "20031") {
                // 账户剩余广告时长不足
                this.getUserInfo();
              }
            });
        } else {
          return false;
        }
      });
    },
    onClick_see() {
      this.preVideoData = {
        dialogUrl: `${this.form.video_url}?${new Date().getTime()}`,
        currentScreen: this.screenList[this.currentScreenIndex].name,
      };
      setTimeout(() => {
        this.$refs.refPreVideo.openDialog();
      }, 100);
    },
    openLaunchTimeDialog() {
      this.$refs.refLaunchTime.openDialog();
    },
    changeDeviceInfo(item) {
      if (this.currentDevice.name != item.name) {
        this.currentDevice = item;
        this.showDeviceInfo = true;
      } else {
        this.showDeviceInfo = !this.showDeviceInfo;
      }
    },
    // 计算开机时间
    openTimeFn() {
      if (this.currentDevice) {
        const openTimeBeginMinute =
          this.currentDevice.openTimeBeginHour * 60 +
          Number(this.currentDevice.openTimeBeginMinute);
        const openTimeEndMinute =
          this.currentDevice.openTimeEndHour * 60 +
          Number(this.currentDevice.openTimeEndMinute);
        let days = (openTimeEndMinute - openTimeBeginMinute) / 60;
        if (!Number.isInteger(days)) {
          days = Math.floor(days);
        }
        return `${this.currentDevice.strOpenTime}（${days}小时/天）`;
        // return `${this.currentDevice.openTimeBeginHour}:${
        //   this.currentDevice.openTimeBeginMinute < 10
        //     ? `0${this.currentDevice.openTimeBeginMinute}`
        //     : this.currentDevice.openTimeBeginMinute
        // }-${this.currentDevice.openTimeEndHour}:${
        //   this.currentDevice.openTimeEndMinute < 10
        //     ? `0${this.currentDevice.openTimeEndMinute}`
        //     : this.currentDevice.openTimeEndMinute
        // }（${days}小时/天）`;
      }
      return "-";
    },
    // 计算宽高比
    aspectRatioFn() {
      if (this.currentDevice) {
        const Width = this.currentDevice.resolutionWidth;
        const Height = this.currentDevice.resolutionHeight;
        const maxNumber = this.maxNumber(Width, Height); // 宽和高的最大公约数
        return `${Width / maxNumber} : ${Height / maxNumber}`;
      }
      return "-";
    },
    // 计算最大公约数
    maxNumber(a, b) {
      if (a > 0 && b > 0) {
        while (b !== 0) {
          const temp = b;
          b = a % b;
          a = temp;
        }
        return a;
      }
      return 1;
    },
    inputNumberBlur1(e) {
      const valid = e.target.ariaValueNow > 0;
      if (!valid) {
        this.form.times = 1;
      }
      this.form.times = Math.floor(this.form.times);
    },
    inputNumberBlur2(e) {
      const valid = e.target.ariaValueNow > 0;
      if (!valid) {
        this.form.smartDaysTimes = 1;
      }
      this.form.smartDaysTimes = Math.floor(this.form.smartDaysTimes);
    },
    inputNumberBlur3(e) {
      const valid = e.target.ariaValueNow > 0;
      if (!valid) {
        // this.form.smartIntervalMinute = 1;
        this.form.smartIntervalMinute = Math.ceil(this.videoDuration / 60);
      }
      this.form.smartIntervalMinute = Math.floor(this.form.smartIntervalMinute);
    },
    inputNumberBlur4(e) {
      const valid = e.target.ariaValueNow > 0;
      if (!valid) {
        this.form.smartDays = 1;
      }
      this.form.smartDays = Math.floor(this.form.smartDays);
    },
    // 智能投放不间隔时，最大可投放次数
    smartNoIntervalMaxTimes() {
      const allSecond = this.todayOpenTimeDifference;
      let max = Math.floor(allSecond / (Math.ceil(this.videoDuration / 6) * 6));
      if (max > 999) {
        max = 999;
      }
      return max;
    },
    // 计算智能投放，每天投放次数是否超出最大可投放次数
    countSmartDaysTimes() {
      const allMinute = this.todayOpenTimeDifference / 60;
      // 不间隔投放
      if (this.smartNoInterval) {
        const smartNoIntervalMaxTimes = this.smartNoIntervalMaxTimes();
        if (this.form.smartDaysTimes > smartNoIntervalMaxTimes) {
          this.form.smartDaysTimes = smartNoIntervalMaxTimes;
        }
        // 每天最大可投放几次控件限制最大值
        this.smartDaysTimesMax = smartNoIntervalMaxTimes;
      } else {
        if (
          this.form.smartDaysTimes >
          allMinute / Math.ceil(this.videoDuration / 60)
        ) {
          this.form.smartDaysTimes = Math.floor(
            allMinute / Math.ceil(this.videoDuration / 60)
          );
        }
        // 每天最大可投放几次控件限制最大值
        this.smartDaysTimesMax = Math.floor(
          allMinute / Math.ceil(this.videoDuration / 60)
        );
      }
      if (this.form.smartDaysTimes > 999) {
        this.form.smartDaysTimes = 999;
      }
      if (this.smartDaysTimesMax > 999) {
        this.smartDaysTimesMax = 999;
      }
      // 超出当前次数可设置的最大间隔，自动改值为最大间隔
      if (
        this.form.smartIntervalMinute >
        Math.floor(allMinute / this.form.smartDaysTimes)
      ) {
        this.form.smartIntervalMinute = Math.floor(
          allMinute / this.form.smartDaysTimes
        );
        if (!this.smartNoInterval) {
          this.showSmartIntervalMinuteDesc = true;
        }
      }
      if (this.form.smartIntervalMinute == 0) {
        this.form.smartIntervalMinute = Math.ceil(this.videoDuration / 60);
      }
      // 智能投放最大间隔控件限制最大值
      this.smartIntervalMinuteMax = Math.floor(
        allMinute / this.form.smartDaysTimes
      );
      if (this.smartIntervalMinuteMax > 100) {
        this.smartIntervalMinuteMax = 100;
      }
    },
    // 处理图片平铺
    tileNumInit(attachSubList) {
      const currentScreenName = this.screenList[this.currentScreenIndex].name;
      if (attachSubList.length > 0 && attachSubList[0] == 1) {
        if (currentScreenName == "default") {
          const width = (this.videoWidth / this.videoHeight) * 266;
          this.tileNum1 = Math.floor(400 / width);
        }
        if (currentScreenName == "yihua" || currentScreenName == "demo") {
          const width = (this.videoWidth / this.videoHeight) * 300;
          this.tileNum1 = Math.floor(400 / width);
        }
        if (currentScreenName == "xinghu") {
          const width = (this.videoWidth / this.videoHeight) * 242;
          this.tileNum1 = Math.floor(1000 / width);
        }
        if (this.tileNum1 < 1) {
          this.tileNum1 = 1;
        }
      }
      if (attachSubList.length > 1 && attachSubList[1] == 1) {
        if (currentScreenName == "default") {
          const width = (this.videoWidth / this.videoHeight) * 266;
          this.tileNum2 = Math.floor(400 / width);
        }
        if (currentScreenName == "yihua" || currentScreenName == "demo") {
          const width = (this.videoWidth / this.videoHeight) * 300;
          this.tileNum2 = Math.floor(400 / width);
        }
        if (currentScreenName == "xinghu") {
          const width = (this.videoWidth / this.videoHeight) * 202;
          this.tileNum2 = Math.floor(400 / width);
        }
        if (this.tileNum2 < 1) {
          this.tileNum2 = 1;
        }
      }
    },
    attachSubListInit() {
      if (this.currentScreenInfo) {
        this.attachSubList = [];
        // 主屏默认展示方式
        if (this.currentScreenInfo.showType.length > 0) {
          this.attachSubList[0] = this.currentScreenInfo.showType[0];
        }
        // 辅屏默认展示方式
        if (this.currentScreenInfo.attachSub.length > 0) {
          if (this.currentScreenInfo.attachSub[0].showType.length > 0) {
            this.attachSubList[1] =
              this.currentScreenInfo.attachSub[0].showType[0];
          }
        }
      }
    },
    screenListInit() {
      const currentVideoRatio = this.decimalCount(this.videoWidth, this.videoHeight);
      this.screenList.forEach((item) => {
        item.disabled = false;
        if (
          item.widthHeightRatioBegin > currentVideoRatio ||
          item.widthHeightRatioEnd < currentVideoRatio
        ) {
          item.disabled = true;
        }
        // 如果当前屏幕和禁用的屏幕名称一样，则切换为可选的屏幕
        if (item.disabled && this.screenList[this.currentScreenIndex].name == item.name) {
          const findIndex = this.screenList.findIndex((v) => !v.disabled);
          if (findIndex != -1) {
            this.clickScreen(this.screenList[findIndex], findIndex);
          }
        }
      });
    },
    // 保留两位小数，不四舍五入
    decimalCount(width, height) {
      let result = '';
      const num = width / height;
      if (Number.isInteger(num)) {
        // 如果是整数，就不需要进行小数点的处理，直接将 num 赋值给 result
        result = num;
      } else {
        result = Math.floor(num * 100) / 100; // 保留两位
      }
      return result;
    }
  },
  beforeDestroy() {
    bus.$emit("isAddLaunchInfo", true);
  },
};
</script>

<style lang="scss" scoped>
/* 自定义谷歌等浏览器滚动条样式 */
::-webkit-scrollbar {
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 255, 255, 1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(230, 230, 230, 1);
  border-radius: 4rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 自定义火狐浏览器滚动条样式 */
* {
  scrollbar-color: #e8e5e9 #fff;
  scrollbar-width: thin;
}
.showTypeFormItem {
  // ::v-deep .el-radio__input {
  //   font-size: 16rem !important;
  // }
  ::v-deep .el-radio__label {
    padding-left: 8rem;
    font-size: 16rem !important;
    color: #252927 !important;
  }
  .popover {
    .reference {
      cursor: pointer;

      img {
        width: 22rem;
        height: 22rem;
        margin-right: 15rem;
      }
    }
  }
  .main-view,
  .vice-view {
    display: flex;
    flex-direction: column;

    .title-view {
      width: 600rem;
      height: 50rem;

      .label {
        font-size: 16rem;
        color: #252927;
        line-height: 22rem;
      }

      .attachSub-icon {
        width: 22rem;
        height: 22rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .img-view {
      background: #333333;
      .img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
      .type2 {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }

  .default {
    .img-view {
      width: 400rem;
      height: 266rem;
    }
  }

  .yihua,.demo {
    .img-view {
      width: 400rem;
      height: 300rem;
    }
  }

  .xinghu {
    .img-view {
      width: 1000rem;
      height: 242rem;
    }
  }

  .xinghu2 {
    .img-view {
      width: 400rem;
      height: 202rem;
    }
  }
}
.change-launch-type {
  position: absolute;
  right: 2rem;
  top: -50rem;
  font-size: 18rem;
  color: #2bb3e3;
  cursor: pointer;
}
.page {
  width: 100%;
  min-height: calc(100vh - 70rem);
  padding: 20rem 320rem 134rem;
  background: #f8f8f8;

  .el-form-item {
    background-color: #fff;
    padding: 40rem 48rem 30rem;

    .screenList {
      overflow-x: auto;
      padding-bottom: 6rem;
      .screenItem {
        width: 206rem;
        height: 188rem;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 12rem;
        border: 1px solid #e0e0e0;
        padding: 20rem 20rem 0;
        margin-left: 24rem;
        cursor: pointer;
        position: relative;
        transition: all 0.3s;

        &:hover {
          background: rgba(50, 195, 246, 0.1);
          border: 1px solid #32c3f6;
        }

        &:nth-child(1) {
          margin-left: 17rem;
        }
        .screen-type {
          height: 24rem;
          line-height: 24rem;
          background: #d4f7fd;
          border-radius: 3rem;
          padding: 0 5rem;
          font-size: 14rem;
          color: #2bb3e3;
          position: absolute;
          right: 18rem;
          bottom: 11rem;
        }
        .attachSub-img {
          width: 184rem;
          height: 56rem;
          position: absolute;
          top: -18rem;
          left: -15rem;
        }

        .image {
          width: 166rem;
          height: 94rem;
          border-radius: 1px;
        }

        span {
          font-size: 16rem;
          color: #252927;
          line-height: 22rem;
          margin-top: 12rem;
        }

        .device-info-btn {
          width: 86rem;
          line-height: 22rem;
          margin-top: 4rem;
          &:hover {
            span {
              color: #32c3f6;
            }
            .down-svg {
              color: #32c3f6;
            }
          }
          span {
            font-size: 16rem;
            color: #9d9f9d;
            margin-top: 0;
            /* 火狐 */
            -moz-user-select: none;
            /* Safari 和 欧朋 */
            -webkit-user-select: none;
            /* IE10+ and Edge */
            -ms-user-select: none;
            /* Standard syntax 标准语法(谷歌) */
            user-select: none;
          }

          .down-svg {
            color: #9d9f9d;
            width: 9rem;
            height: 9rem;
            margin-left: 5rem;
          }
          .down {
            width: 20rem;
            height: 20rem;
          }
        }
      }

      .active {
        background: rgba(50, 195, 246, 0.1);
        border: 1px solid #32c3f6;
      }

      .disabled {
        .disabled-view {
          width: 100%;
          height: 100%;
          text-align: center;
          border-radius: 12rem;
          border: none;
          background: rgba(0, 0, 0, 0.55);
          position: absolute;
          top: 0;
          left: 0;
          font-size: 16rem;
          color: #ffffff;
          line-height: 22rem;
          z-index: 1;
          cursor: not-allowed;
        }
        &:hover {
          background: #ffffff;
          border: 1px solid #e0e0e0;
        }
      }
    }
    .device-info {
      width: 1150rem;
      // height: 408rem;
      background: #f8f8f8;
      border-radius: 12rem;
      margin: 22rem 0 0 17rem;
      padding: 20rem 32rem;
      position: relative;
      .info-item {
        width: 500rem;
        height: 46rem;
        margin-right: 86rem;
        &:nth-child(2) {
          margin-right: 0;
        }
        &:nth-child(4) {
          margin-right: 0;
        }
        &:nth-child(6) {
          margin-right: 0;
        }
        &:nth-child(8) {
          margin-right: 0;
        }
        &:nth-child(10) {
          margin-right: 0;
        }
        .label {
          display: inline-block;
          width: 136rem;
          font-size: 16rem;
          color: #919391;
        }
        .value {
          font-size: 16rem;
          color: #252927;
          line-height: 20rem;
          // margin-left: 24rem;
        }
      }
      .max-width {
        width: 100%;
        .value {
          display: inline-block;
          width: 920rem;
        }
      }
      .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 32rem;
        height: 28rem;
        background: #e8e8e8;
        border-radius: 0px 12rem 0px 12rem;
        cursor: pointer;
        img {
          width: 20rem;
          height: 20rem;
        }
      }
    }

    .el-select {
      width: 328rem;
      height: 40rem;
      border-radius: 12rem;
      margin-left: 17rem;
    }

    .no-discount {
      width: 328rem;
      height: 48rem;
      border-radius: 12rem;
      border: 1rem solid #e0e0e0;
      margin-left: 20rem;
      text-align: left;
      line-height: 48rem;
      font-size: 16rem;
      color: #a8a9a9;
      padding-left: 21rem;
    }

    .date-desc {
      display: block;
      font-size: 16rem;
      color: #a1a3a2;
      line-height: 22rem;
      margin-top: 20rem;
    }
    .warning-desc {
      font-size: 15rem;
      color: #d68c2e;
      line-height: 22rem;
      margin-bottom: 16rem;
      img {
        width: 18rem;
        height: 18rem;
        margin-right: 5rem;
      }
    }
    .title_box {
      margin-top: 40rem;
      i {
        width: 3rem;
        height: 18rem;
        margin-right: 14rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }
      span {
        font-size: 20rem;
        color: #252927;
      }
    }

    .duration-desc {
      font-size: 16rem;
      color: #252927;
      line-height: 22rem;
      padding: 8rem 0 0 34rem;

      div {
        margin-top: 15rem;
      }
      span {
        display: inline-block;
        margin-top: 15rem;
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      .tip {
        font-size: 15rem;
        color: #d68c2e;
        line-height: 21rem;
        img {
          width: 20rem;
          height: 20rem;
          margin-right: 5rem;
        }
      }
    }

    .video-view {
      width: 240rem;
      height: 152rem;
      background: #333333;
      border-radius: 6rem;
      position: relative;

      .logo_seize {
        position: absolute;
        top: 43rem;
        left: 86rem;
        width: 69rem;
        z-index: 1;
      }

      .video {
        // position: absolute;
        // top: 0;
        // left: 0;
        border-radius: 6rem;
        cursor: pointer;
        width: 100%;
        height: 100%;
        z-index: 2;
        object-fit: contain;
      }
    }
  }

  .footer {
    width: 100%;
    padding: 21rem 320rem 21rem;
    background-color: #fff;
    border-top: 1rem solid rgba(0, 0, 0, 0.15);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    .total-duration {
      font-size: 18rem;
      color: #1fb0e4;
      line-height: 25rem;
    }

    .discount {
      font-size: 16rem;
      color: #787878;
      line-height: 22rem;
      margin-top: 3rem;
    }

    .paid-in {
      font-size: 18rem;
      color: #1fb0e4;
      line-height: 25rem;
    }

    .submit-order,
    .loading-btn {
      width: 180rem;
      height: 50rem;
      border-radius: 31rem;
      margin-left: 30rem;
      text-align: center;
      line-height: 50rem;
      color: #fff;
      font-weight: 500;
    }
  }
}

::v-deep .el-form-item__label {
  height: 18rem;
  line-height: 18rem;
  margin-bottom: 24rem;
  border-left: 3rem solid #3bbcf9;
  padding-left: 14rem;
  font-size: 18rem;
  color: #252927;
}

::v-deep .el-input__inner {
  height: 40rem;
  line-height: 40rem;
}

::v-deep .el-input-number {
  width: 230rem;
  height: 40rem;
  line-height: 40rem;
}

::v-deep .el-input-number__decrease,
::v-deep .el-input-number__increase {
  border: none;
  width: 24rem;
  height: 24rem;
  line-height: 24rem;
  border-radius: 4rem;
  background: #fff;
  color: #333;
  font-size: 16rem;
  top: 8rem;
}

::v-deep .el-input-number__decrease {
  left: 14rem;

  &:hover {
    background-color: #e7e7e7;
  }
}

::v-deep .el-input-number__increase {
  right: 14rem;

  &:hover {
    background-color: #e7e7e7;
  }
}

::v-deep .el-icon-minus {
  color: #252927;
  font-weight: 600;
}

::v-deep .el-icon-plus {
  color: #252927;
  font-weight: 600;
}

::v-deep .el-icon-time:before {
  content: "";
}

.timesRadioGroup {
  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio {
    margin-right: 25rem;
    margin-top: 14rem;
    margin-left: 0 !important;
    font-weight: 400 !important;
  }

  ::v-deep .el-radio.is-bordered {
    width: 168rem;
    height: 40rem;
    text-align: center;
    line-height: 40rem;
    border-radius: 11rem;
    border: 1rem solid #e0e0e0;
    padding: 0;
    transition: all 0.3s;

    &:hover {
      border: 1rem solid #32c3f6;
    }
  }

  ::v-deep .el-radio.is-disabled {
    width: 168rem;
    height: 40rem;
    text-align: center;
    line-height: 40rem;
    border-radius: 11rem;
    background-color: #eeeeee;
    border: none;
    padding: 0;
    transition: all 0.3s;
    &:hover {
      border: none;
    }
  }

  ::v-deep .el-radio.is-checked {
    background-color: #edf8ff;
    border: 1rem solid #32c3f6;
  }

  ::v-deep .el-radio__label {
    padding-left: 0;
    font-size: 15rem !important;
    color: #252927 !important;
  }
}

::v-deep .el-input__prefix {
  display: none;
}

::v-deep .el-form-item__content {
  line-height: 40rem;
}

::v-deep .el-form-item__content .date-img {
  width: 32rem;
  position: absolute;
  left: 16rem;
  top: 4rem;
  cursor: pointer;
}

::v-deep .el-input__inner {
  border-radius: 12rem;
  font-size: 15rem;
  font-weight: 400;
  color: #252927;
}

::v-deep .el-input--prefix .el-input__inner {
  text-align: center;
  padding-left: 25rem;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 0;
}

::v-deep .el-select .el-input .el-select__caret {
  font-size: 20rem;
  color: #252927;
  line-height: 40rem;
}

::v-deep .el-input__suffix {
  right: 15rem;
}
::v-deep .el-checkbox__label {
  font-size: 18rem;
  font-weight: 400;
  color: #252927;
  line-height: 25rem;
}

@media (max-width: 1100px) {
  ::v-deep .el-date-editor {
    width: 280rem !important;
  }
}

@media (min-width: 1101px) and (max-width: 1280px) {
  ::v-deep .el-date-editor {
    width: 240rem !important;
  }
}

@media (min-width: 1281px) {
  ::v-deep .el-date-editor {
    width: 230rem !important;
  }
}
.datePicker {
  width: 176rem !important;
  .el-input__inner {
    width: 176rem !important;
  }
}
.input_number {
  width: 80rem !important;
  margin: 0 12rem;
}
.smartLaunch-item {
  margin-bottom: 40rem;
}
.smartLaunch-item_text {
  font-size: 18rem;
  font-weight: 400;
  color: #252927;
  line-height: 25rem;
}
.smartNoInterval-view {
  margin-left: 20rem;
  span {
    font-size: 16rem;
    color: #a1a3a2;
    line-height: 22rem;
  }
}
</style>
