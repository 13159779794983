<template>
  <el-dialog
    title=""
    :visible.sync="orderErrVisible"
    width="762rem"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="dialog"
  >
    <div class="dialog_box flex-col">
      <div class="title-view flex-row align-center">
        <div class="border-left"></div>
        <span
          >您当前已有3张“待支付”的订单，请先支付，您才能继续创建新订单。</span
        >
      </div>
      <div class="desc flex-row align-center">
        {{ num }}&nbsp;秒后自动跳转到<span class="txt-btn" @click="goOrderList"
          >我的订单</span
        >页面
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      orderErrVisible: false,
      num: 3,
      timer: null,
    };
  },
  created() {},
  methods: {
    // 打开弹窗
    openDialog() {
      this.orderErrVisible = true;
      this.num = 3;
      this.timer = null;
      this.timer = setInterval(() => {
        if (this.num == 0) {
          this.goOrderList();
          return;
        }
        this.num--;
      }, 1000);
    },
    // 点击确定支付
    goOrderList() {
      this.$router.push({
        name: "MyOrderIndex",
      });
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .dialog_box {
    .title-view {
      .border-left {
        width: 3rem;
        height: 18rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }
      span {
        font-size: 18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #252927;
        line-height: 25rem;
        margin-left: 14rem;
      }
    }
    .desc {
      margin-top: 28rem;
      margin-left: 13rem;
      font-size: 18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #252927;
      line-height: 25rem;
      .txt-btn {
        color: #00B6E8;
        text-decoration: underline;
        margin: 0 5rem;
        cursor: pointer;
      }
    }
  }
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 68rem;
}
</style>
