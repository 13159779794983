<template>
  <el-dialog
    title=""
    :visible.sync="launchTimeStatus"
    width="1140rem"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    class="dialog"
  >
    <div class="dialog-box flex-col">
      <div class="dialog-title-view flex-col">
        <span>订单已生成，请确认投放时间</span>
        <div></div>
      </div>
      <div class="remark flex-row align-center">
        <span>投放广告的屏幕</span>
        <div class="screen">{{ orderData.remark }}</div>
      </div>
      <div class="table flex-row justify-between">
        <div class="divider"></div>
        <div class="table-item flex-col justify-between align-center">
          <span>投放次数</span>
          <span>{{ orderData.times }}次</span>
        </div>
        <div
          v-if="!orderData.smart"
          class="table-item flex-col justify-between align-center"
        >
          <span>投放时间间隔</span>
          <span v-if="orderData.times > 1 && orderData.intervalMinute == 0"
            >不间隔，连续投放</span
          >
          <span v-else-if="orderData.times > 1"
            >每{{
              orderData.intervalMinute == 1440
                ? "1天"
                : orderData.intervalMinute >= 60
                ? orderData.intervalMinute / 60 + "小时"
                : orderData.intervalMinute + "分钟"
            }}投放1次</span
          >
          <span v-else>-</span>
        </div>
        <div
          v-if="orderData.smart"
          class="table-item flex-col justify-between align-center"
        >
          <span>投放时间间隔</span>
          <span v-if="orderData.times > 1 && orderData.intervalMinute == 0"
            >不间隔，连续投放</span
          >
          <span v-else-if="orderData.times > 1 && orderData.smartDaysTimes > 1"
            >每{{ orderData.intervalMinute }}分钟投放1次</span
          >
          <span v-else-if="orderData.times > 1 && orderData.smartDaysTimes == 1"
            >每1天投放1次</span
          >
          <span v-else>-</span>
        </div>
        <div class="table-item flex-col justify-between align-center">
          <span>开始投放时间</span>
          <span>{{ orderData.beginUnix | dateFormat }}</span>
        </div>
        <!-- <div class="table-item flex-col justify-between align-end">
          <span>计费单价</span>
          <span>每秒 ¥1.00</span>
        </div> -->
      </div>
      <div class="ad-view flex-col">
        <div class="ad-titlt flex-row align-center">
          <span>所有投放时间：</span>
        </div>
        <div
          class="ad-list"
          v-infinite-scroll="launchTimeLoad"
          :infinite-scroll-disabled="infiniteScrollDisabled"
          infinite-scroll-distance="1"
        >
          <span v-for="(item, index) in adList" :key="index" class="adItem">{{
            item.beginUnix | dateFormat
          }}</span>
        </div>
        <div class="bg"></div>
      </div>
      <span class="bottom-desc"
        >*
        系统已自动给您挑选离预约时间点最近的可投放时间，并在您确认之后再进行付款。</span
      >
      <div slot="footer" class="dialog-footer flex-row justify-center">
        <div v-if="!submitStatus" @click="cancelOrder" class="cancel-btn">
          取消订单
        </div>
        <div
          v-else
          class="cancel-btn flex-row justify-center align-center"
          id="primary-btn"
        >
          <img src="@/assets/img/loading.gif" width="60" height="60" />
        </div>
        <div
          v-if="!submitStatus"
          @click="goPay"
          class="pay-btn"
          id="primary-btn"
        >
          去支付
        </div>
        <div v-else class="pay-btn" id="disable-btn">去支付</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable vue/require-valid-default-prop */
import { getListAd, cancelOrder } from "@/api/request";
import getOrderStatus from "../../../utils/getOrderStatus";

export default {
  props: {
    orderData: {
      type: Object,
      default: {},
      required: true,
    },
  },
  data() {
    return {
      launchTimeStatus: false,
      submitStatus: false,
      adList: [],
      pageParams: {
        statusList: [],
        orderId: "",
        Asc: true, // 升序排列
        name: "", // 屏幕名称
        pageLimit: {
          limit: 50,
          page: 1,
        },
      },
      infiniteScrollDisabled: false,
      loading: null
    };
  },
  created() {},
  computed: {},
  methods: {
    // 打开弹窗
    openDialog() {
      document.body.style.overflow = "hidden"; // 浏览器滚动条隐藏且禁用
      this.infiniteScrollDisabled = false;
      this.submitStatus = false;
      this.launchTimeStatus = true;
      this.adList = [];
      this.pageParams.pageLimit.page = 1;
      this.pageParams.name = this.orderData.name;
      this.pageParams.orderId = this.orderData.orderId;
      this.getPageListAdEvent();
    },
    // 点击取消订单
    cancelOrder() {
      document.body.style.removeProperty("overflow"); // 浏览器滚动条显示且可用
      this.submitStatus = true;
      cancelOrder({
        order_id: this.orderData.orderId,
        user_delete: true
      })
        .then(() => {
          this.submitStatus = false;
          this.launchTimeStatus = false;
        })
        .catch((code) => {
          this.submitStatus = false;
          this.launchTimeStatus = false;
          // 订单已经支付/超时了
          if (code == "20012" || code == "20013") {
            let BASE_URL = "";
            BASE_URL = process.env.VUE_APP_BASE_URL;
            window.open(
              `${BASE_URL}/myOrder/detail?orderId=${this.orderData.orderId}`
            );
          }
        });
    },
    // 自动取消订单
    automaticCancelOrder() {
      document.body.style.removeProperty("overflow"); // 浏览器滚动条显示且可用
      this.launchTimeStatus = false;
      cancelOrder({
        order_id: this.orderData.orderId,
      })
        .then(() => {
          console.log("自动取消订单成功");
        })
        .catch(() => {});
    },
    goPay() {
      getOrderStatus(this.orderData.orderId)
        .then((status) => {
          // 如果是免审视频下单，并且当前时间超过了第一次投放时间，并且订单是待支付状态
          if (this.orderData.videoDirect && !this.decideBeginUnix() && status == 0) {
            this.automaticCancelOrder();
            this.$message({
              dangerouslyUseHTMLString: true,
              showClose: true,
              duration: 5000,
              type: "warning",
              message:
            "<p style='margin-bottom: 10rem;font-size: 16rem'>已过第一次投放时间，无法支付！</p><p style='font-size: 12rem'>由于您停留时间较长，当前时间已过第一次投放时间，无法进行支付，请在当前页面重新选择投放时间。</p>",
            });
            return;
          }
          this.requestPay();
        })
        .catch(() => {
          this.requestPay();
        });
    },
    requestPay() {
      document.body.style.removeProperty("overflow"); // 浏览器滚动条显示且可用
      this.launchTimeStatus = false;
      this.$router.replace({
        name: "PayOrder",
        query: {
          orderId: this.orderData.orderId,
        },
      });
    },
    // 分页获取广告数据
    getPageListAdEvent() {
      setTimeout(() => {
        this.loading = this.$loading({
          lock: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0)'
        });
      }, 10);
      getListAd(this.pageParams)
        .then((res) => {
          this.currentPages = res.pageResult.pages;
          this.adList = [...this.adList, ...res.adList];
          this.$nextTick(() => {
            this.infiniteScrollDisabled = false;
          });
          if (this.loading) {
            this.loading.close();
          }
        })
        .catch(() => {
          if (this.pageParams.pageLimit.page > 1) {
            this.pageParams.pageLimit.page--;
            this.infiniteScrollDisabled = false;
          }
          if (this.loading) {
            this.loading.close();
          }
        });
    },
    // 投放的广告时间区域下拉到底分页加载
    launchTimeLoad() {
      if (this.pageParams.pageLimit.page < this.currentPages) {
        this.pageParams.pageLimit.page++;
        console.log(
          "触发了下拉到底，进行分页请求，请求页码为",
          this.pageParams.pageLimit.page
        );
        this.infiniteScrollDisabled = true;
        this.getPageListAdEvent();
      }
    },
    decideBeginUnix() {
      const targetTimestamp = this.adList[0].beginUnix * 1000;
      const currentTimestamp = Date.now();
      let result = null;
      // 判断当前时间是否小于第一次投放的时间
      if (currentTimestamp < targetTimestamp) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .dialog-box {
    .dialog-title-view {
      span {
        font-size: 22rem;
        color: #252927;
        line-height: 30rem;
      }
      div {
        width: 52rem;
        height: 3rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
        margin-top: 20rem;
      }
    }
    .remark {
      width: 100%;
      height: 80rem;
      background: #ffffff;
      border-radius: 6rem;
      margin-top: 26rem;
      padding-left: 30rem;
      span {
        font-size: 18rem;
        color: #252927;
        line-height: 25rem;
      }
      .screen {
        border-radius: 11rem;
        border: 1px solid #e0e0e0;
        margin-left: 23rem;
        padding: 9rem 21rem;
        font-size: 16rem;
        color: #252927;
        line-height: 22rem;
      }
    }
    .table {
      width: 100%;
      background: #ffffff;
      border-radius: 6rem;
      padding: 0 30rem;
      margin-top: 20rem;
      position: relative;
      .divider {
        width: 100%;
        height: 2rem;
        position: absolute;
        left: 0;
        top: 46rem;
        background-color: #f5f5f5;
      }
      .table-item {
        height: 110rem;
        padding: 12rem 0 20rem;
        span:nth-child(1) {
          font-size: 16rem;
          color: #a0a2a0;
        }
        span:nth-child(2) {
          font-size: 16rem;
          color: #252927;
        }
      }
    }
    .ad-view {
      width: 100%;
      padding: 24rem 8rem 0 30rem;
      background: #ffffff;
      border-radius: 6rem;
      margin-top: 20rem;
      .ad-titlt {
        height: 18rem;
        line-height: 18rem;
        margin-bottom: 10rem;
        font-size: 18rem;
        color: #252927;

        .desc {
          // width: 316rem;
          padding: 0 8rem;
          height: 32rem;
          background: rgba(234, 145, 35, 0.08);
          border-radius: 12rem;
          text-align: center;
          line-height: 32rem;
          font-size: 15rem;
          color: #da7f0d;
          // margin-left: 30rem;
        }
      }
      .ad-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-height: 130rem;
        overflow-y: auto;
        position: relative;
        // right: 28rem;
        padding-bottom: 20rem;
        .adItem {
          font-size: 16rem;
          color: #3b3e3c;
          margin: 12rem 28rem 0 0;
        }
      }
      .bg {
        position: relative;
        bottom: 23rem;
        width: 98%;
        height: 23rem;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.5) 0%,
          #ffffff 100%
        );
      }
    }
    .bottom-desc {
      font-size: 18rem;
      color: #a2a3a3;
      line-height: 25rem;
      margin-top: 20rem;
    }
    .dialog-footer {
      width: 100%;
      margin-top: 40rem;
      .cancel-btn {
        width: 168rem;
        height: 46rem;
        line-height: 46rem;
        border-radius: 25rem;
        text-align: center;
        font-size: 18rem;
        font-weight: 500;
        color: #2bb3e3;
        background: #ffffff;
        border-radius: 25rem;
        border: 1rem solid #32c3f6;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
          color: #fff;
        }
      }
      .pay-btn {
        width: 168rem;
        height: 46rem;
        text-align: center;
        line-height: 46rem;
        font-size: 18rem !important;
        font-weight: 500 !important;
        color: #fff;
        border-radius: 25rem;
        margin-left: 44rem;
      }
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 60rem 58rem 48rem;
}
</style>
